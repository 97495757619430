<template>
  <div class="svg_holder" :class="[color, size]">
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><g><rect fill="none"/></g><g><g><g><path d="M3,18L3,18c0,0.55,0.45,1,1,1h5v-2H4C3.45,17,3,17.45,3,18z M3,6L3,6c0,0.55,0.45,1,1,1h9V5H4C3.45,5,3,5.45,3,6z M13,20 v-1h7c0.55,0,1-0.45,1-1l0,0c0-0.55-0.45-1-1-1h-7v-1c0-0.55-0.45-1-1-1l0,0c-0.55,0-1,0.45-1,1v4c0,0.55,0.45,1,1,1l0,0 C12.55,21,13,20.55,13,20z M7,10v1H4c-0.55,0-1,0.45-1,1l0,0c0,0.55,0.45,1,1,1h3v1c0,0.55,0.45,1,1,1l0,0c0.55,0,1-0.45,1-1v-4 c0-0.55-0.45-1-1-1l0,0C7.45,9,7,9.45,7,10z M21,12L21,12c0-0.55-0.45-1-1-1h-9v2h9C20.55,13,21,12.55,21,12z M16,9L16,9 c0.55,0,1-0.45,1-1V7h3c0.55,0,1-0.45,1-1l0,0c0-0.55-0.45-1-1-1h-3V4c0-0.55-0.45-1-1-1l0,0c-0.55,0-1,0.45-1,1v4 C15,8.55,15.45,9,16,9z"/></g></g></g></svg>
  </div>
</template>

<script>
export default {
  name: 'IconFilter',
  props: ['size', 'color']
}
</script>

<style scoped>
  .svg_holder {
    display: -webkit-flex; /* Safari */
    -webkit-align-items: center; /* Safari 7.0+ */
    display: flex;
    align-items: center;
    -webkit-justify-content:center;
    justify-content:center;
  }
</style>
